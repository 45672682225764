import { useForm, getFormProps, getInputProps } from "@conform-to/react";
import { parseWithZod, getZodConstraint } from "@conform-to/zod";
import { Form, useActionData, useSearchParams } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import { useIsPending } from "../hooks/useIsPending";

import { Field, PasswordField, ErrorList, useTranlateErrors } from "./forms";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { Button } from "./ui/button";
import { type action } from "~/routes/login";

export const loginSchema = z.object({
  email: z
    .string({ required_error: "login.error.email-required" })
    .email("login.error.email-format")
    .transform((value) => value.toLowerCase()),

  password: z
    .string({ required_error: "login.error.email-required" })
    .min(6, "login.error.password-min")
    .max(32, "login.error.password-max"),
  redirectTo: z.string().optional(),
});

export const LoginForm = () => {
  const actionData = useActionData<typeof action>();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");

  const [form, fields] = useForm({
    id: "login-form",
    constraint: getZodConstraint(loginSchema),
    defaultValue: { redirectTo },
    lastResult: actionData?.result,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: loginSchema });
    },
    shouldValidate: "onBlur",
    shouldRevalidate: "onInput",
  });

  const isPending = useIsPending();

  const { t } = useTranslation();

  const { translateErrors } = useTranlateErrors();

  return (
    <Form
      method="POST"
      className="w-full flex flex-col gap-2"
      {...getFormProps(form)}
    >
      <Field
        className="grid gap-0.5 relative"
        labelProps={{
          children: t("common.form.label.email"),
          className: "text-neutral-600 font-normal",
        }}
        inputProps={{
          ...getInputProps(fields.email, { type: "email" }),
          autoFocus: true,
          className: "h-12 mt-2 focus-visible:ring-blue-600",
          autoComplete: "email",
        }}
        errors={translateErrors(fields.email.errors)}
      />
      <PasswordField
        className="grid gap-0.5 relative"
        labelProps={{
          children: t("common.form.label.password"),
          className: "text-neutral-600 font-normal",
        }}
        inputProps={{
          ...getInputProps(fields.password, { type: "password" }),
          className: "h-12 mt-2 focus-visible:ring-blue-600",
          autoComplete: "current-password",
        }}
        errors={translateErrors(fields.password.errors)}
      />
      <input {...getInputProps(fields.redirectTo, { type: "hidden" })} />
      <ErrorList errors={translateErrors(form.errors)} id={form.errorId} />
      <div className="flex w-full justify-end">
        <LanguageSwitcher />
      </div>

      <Button
        variant="default"
        type="submit"
        size="lg"
        className="mt-2 leading-6 p-5 text-lg bg-blue-800"
      >
        {isPending ? t("login.signing-in") : t("login.signin")}
      </Button>
    </Form>
  );
};
